import React from 'react';

const KeyRemortgage = () => {
  return (
    <div>
          <div className="takeaways">
            <h2>Key Takeaways</h2>
            <ul>
              <li>Seek professional advice from a mortgage adviser</li>
              <li>
              There will be an element of legal work so you will need a solicitor so it’s important to factor in their costs
              </li>
              <li>
              There may be incentives to move with them like cashback and free legal advice
              </li>
              <li>
                Have all of your documents required (ID, Payslips, and bank statements, tax return)
              </li>
            </ul>
          </div>
          <div className="transcript">
            <h2>Transcript</h2>
            <p>
              Key things to consider when you remortgage.<br/><br/>
              When you remortgage, it is important to consider all of the options available to you and the best way to find this out is to seek professional advice.<br /><br />
              In today's market, it is very easy for people to take up a new deal online or an app with their existing mortgage lender. And whilst it may look attractive and super convenient to do so, it may not necessarily be the best deal. And there could be thousands of pounds to save with a competitor.<br /><br />
              Remortgaging is the process whereby a new lender comes in to provide a mortgage, paying off the existing one and taking over. This process has an element of legal work, so you will need a solicitor involved. So it's important to factor in the cost of their professional services when working out whether remortgaging is the best thing to do or not.<br /><br />
              Often lenders offer incentives to move to them. For example, a free valuation of your home, cashback when the new mortgage starts, or even a free legal service to remortgage. It's important to make sure you have all of the useful documents that are involved in a mortgage application on hand, ready to go when you're remortgaging. These include copies of ID, pay slips if you're employed, tax returns if you're self-employed, bank statements, and some others. <br /><br />
              On the note of self-employment by the way, if you are self-employed, it is important to know that a lot of lenders will want the most recent tax return. And if your most recent one is too old, then you may not be in the right position to apply for a new mortgage. <br /><br />
              Keeping your accounts and tax returns up to date is really important when your mortgage is coming around to the end of this deal. Ultimately, the decision on the best options to remortgage depends on your individual circumstances and priorities. It's important to weigh the potential benefits and risks of each option and speak to a mortgage adviser to help you make an informed decision.<br /><br />
            </p>
          </div>
    </div>
  );
};

export default KeyRemortgage;
